// import { useState, useEffect, useCallback } from 'react';

export const useLocalStorage = () => {
  const isBrowser = () => typeof window !== 'undefined';

  const getLocalStorageValue = (key) =>
    isBrowser() && window.localStorage.getItem(key) !== 'undefined'
      ? JSON.parse(window.localStorage.getItem(key))
      : undefined;

  const setLocalStorageValue = (key, value) =>
    isBrowser() ? window.localStorage.setItem(key, JSON.stringify(value)) : {};

  return {
    isBrowser,
    getLocalStorageValue,
    setLocalStorageValue,
  };
};
