
// import Provider from './provider';
// const React = require('react')
// const { Provider } = require('./provider')
import React from 'react';
import { NavProvider } from './src/context/NavProvider';
import { ConfiguratorProvider  } from './src/context/ConfiguratorProvider';

// FOUC
// in use
// export function onClientEntry() {
//   window.addEventListener('load', () => {
//     document.body.className = document.body.className.replace(/\bno-js\b/, '');
//   });
// }
// not in use
// exports.onClientEntry = () => {
//   window.addEventListener('load', () => {
//     document.body.className = document.body.className.replace(/\bno-js\b/, '');
//   });
// };

export const wrapRootElement = ({ element }) => (
  <ConfiguratorProvider>
    <NavProvider>{element}</NavProvider>
  </ConfiguratorProvider>
)


// export function wrapRootElement() { return <Provider />; }
// exports.wrapRootElement = () => <Provider />
// export const wrapRootElement = Provider;
// export default wrapRootElement = Provider;